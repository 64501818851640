<template>
    <b-card title="Premaknjene vloge">
        <Table class="mt-2" :columns="columns" :data="items" v-slot="props">
            <span v-if="props.column.display_type === 1">
                <router-link :to="{name: 'submission', params: {id: props.row.id}}">{{ props.formattedRow[props.column.field] }}</router-link>
            </span>
            <span v-else-if="props.column.display_type === 2">

                <b-badge v-if="props.row.submission.status === 1" variant="success">Privzeto</b-badge>
                <b-badge v-else variant="danger">Zavrnjeno</b-badge>
            </span>
            <span v-else-if="props.column.display_type === 3" class="d-flex justify-content-center">
                {{ $dayjs(props.row.date).format('DD.MM.YYYY') }}
            </span>
            <span v-else >
                {{ props.formattedRow[props.column.field] }}
            </span>
        </Table>

        <primary-school-modal ref="primarySchoolModal" @itemAdded="loadData" @itemEdited="loadData"/>
    </b-card>
</template>

<script>
    import { BCard, BBadge } from 'bootstrap-vue'
    import Table from '@/views/components/Table.vue'
    import PrimarySchoolModal from '@/views/admin/primarySchools/components/PrimarySchoolModal.vue'

    export default {
        components: {
            PrimarySchoolModal,
            Table,
            BCard,
            BBadge
        },
        data() {
            return {
                columns: [
                    {
                        field: (value) => { return [value.submission.first_name, value.submission.last_name, value.submission.gender] },
                        label: 'Ime in priimek (spol)',
                        tdClass: 'align-middle font-weight-bold',
                        formatFn: (values) => {
                            return `${values[0]} ${values[1]} (${this.gender(values[2])})`
                        },
                        filterOptions: {
                            enabled: true,
                            filterFn: (data, search) => {
                                if ((`${data[0]} ${data[1]}`).toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1) return true
                                else if (this.gender(data[2]) === 'M' && ('M').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1) return true
                                else if (this.gender(data[2]) === 'Ž' && ('Ž').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1) return true
                                return false
                            }
                        },
                        display_type: 1
                    },
                    {
                        field: 'submission.status',
                        label: 'Status',
                        tdClass: 'align-middle',
                        display_type: 2,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field: 'from_term.name',
                        label: 'Prejšnji termin',
                        tdClass: 'align-middle',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field: 'to_term.name',
                        label: 'Nov termin',
                        tdClass: 'align-middle',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field: 'date',
                        label: 'Datum premika',
                        tdClass: 'align-middle',
                        display_type: 3,
                        filterOptions: {
                            enabled: true
                        }
                    }
                ],
                items: []
            }
        },
        methods: {
            gender(gender) {
                return gender === 1 ? 'M' : gender === 2 ? 'Ž' : ''
            },
            async loadData() {
                try {
                    this.$store.commit('app/START_LOADING')
                    const response = await this.$http.get('/api/private/v1/moved_submission/')
                    this.items = response.data ?? []
                } catch (error) {
                    this.$printError('Prišlo je do napake pri nalaganju podatkov')
                } finally {
                    this.$store.commit('app/STOP_LOADING')
                }
            }
        },
        async mounted() {
            await this.loadData()
        }
    }
</script>

<style>

</style>
